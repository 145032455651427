import React, { useContext, useState } from 'react'
import InputSearch from '../InputSearch'
import FilterType from '../FilterType'
import Select from '../Select'
import filterIcon from '../../images/svg/filterIcon.svg'
import { FilterContext } from '../../context/filter'
import { CategoryContext } from '../../context/categorys'
import { ServiceContext } from '../../context/service'
const Filter = ({ type }) => {

    const [modal, setModal] = useState(false)

    const { filter } = useContext(FilterContext)
    const { getCategoryByName } = useContext(CategoryContext)
    const { getProductByName } = useContext(ServiceContext)

    return (

        type == 1

            ?

            <div className='filter'>
                <InputSearch onChange={(e) => filter(type, e.target.value)} placeholder="Pesquisar" />

                <div className='container_filter_modal'>
                </div>

            </div>

            :

            type == 3

                ?

                <div className='filter_provider_button'>
                    <div className='row_provider'>
                        <InputSearch onChange={(e) => getCategoryByName(e.target.value)} placeholder="Pesquisar" />
                    </div>
                    <button data-toggle="modal" data-target="#categoryModal" >Nova Marca</button>
                </div>

                :

                type == 4

                    ?


                    <div className='filter_provider_button'>
                        <div className='row_provider'>
                            <InputSearch onChange={(e) => getProductByName(e.target.value)} placeholder="Pesquisar" />
                        </div>
                        <button data-toggle="modal" data-target="#service" >Novo Produto</button>
                    </div>


                    :

                    type == 5

                        ?

                        <div className='filter_provider_button'>
                            <div className='row_provider'>
                                <InputSearch onChange={(e) => getProductByName(e.target.value)} placeholder="Pesquisar" />
                            </div>
                            <button data-toggle="modal" data-target="#categoryModal" >Novo Categoria</button>
                        </div>


                        :



                        type == "financial"

                            ?
                            <div className='filter'>
                                <InputSearch onChange={(e) => filter(type, e.target.value)} placeholder="Pesquisar" />

                                <div className='container_filter_modal'>
                                </div>

                            </div>
                            :


                            type == "schedule"

                                ?
                                <div className='filter'>
                                    <InputSearch onChange={(e) => filter(type, e.target.value)} placeholder="Pesquisar" />

                                    <div className='container_filter_modal'>
                                    </div>

                                </div>
                                :




                                <div className='filter_provider_button'>




                                    <div className='row_provider'>

                                        <InputSearch onChange={(e) => filter(type, e.target.value)} placeholder="Pesquisar" />
                                        <div className='container_filter_modal'>


                                        </div>
                                        <FilterType />
                                    </div>


                                    <button data-toggle="modal" data-target="#userProvider">Novo prestador</button>
                                </div>
    )
}

export default Filter