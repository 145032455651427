import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import userIconSidebar from '../../images/svg/userIconSidebar.svg'
import userIconNotChecked from '../../images/svg/usericonNotCheckeed.svg'
import companyIcon from '../../images/png/LOGO-TKG.jpg'
import financialIcon from '../../images/svg/financialIcon.svg'
import financialCheck from '../../images/svg/financialCheck.svg'
import categoriesIcon from '../../images/svg/categoriesIcon.svg'
import acessIcon from '../../images/svg/acessIcon.svg'
import exitIcon from '../../images/svg/exitIcon.svg'
import serviceIcon from '../../images/svg/serviceIcon.svg'
import categoryCheck from '../../images/svg/categoryCheck.svg'
import acessCheckIcon from '../../images/svg/acessCheckIcon.svg'
import serviceCheckIcon from '../../images/svg/serviceCheckIcon.svg'
import dashboardIcon from '../../images/svg/dashBoardIcon.svg'
import dashboardCheckIcon from '../../images/svg/dashboardCheck.svg'

const Sidebar = () => {
    const [option, setOption] = useState(1)

    const navigate = useNavigate()

    const exit = () => {
        localStorage.clear()
        window.location.href = "/"
    }



    return (

        <div className="sidebar">
            <img className='company_sidebar' src={companyIcon} />

            {localStorage.getItem("@PERMISSION") == "5"

                ?
                <></>
                :
                window.location.pathname == "/dashboard/"

                    ?
                    <div className='checked' onClick={() => { navigate("/dashboard") }}>
                        <img src={dashboardCheckIcon} />
                        <p>Dashboard</p>
                    </div>
                    :

                    <div className='not_checked' onClick={() => { navigate("/dashboard/") }}>
                        <img src={dashboardIcon} />
                        <p>Dashboard</p>
                    </div>
            }

            {/* {localStorage.getItem("@PERMISSION") == "5"

                ?
                <></>
                :

                window.location.pathname == "/dashboard/financial"

                    ?
                    <div className='checked' onClick={() => { navigate("/dashboard/financial") }}>
                        <img src={financialCheck} />
                        <p>Financeiro</p>
                    </div>
                    :
                    <div className='not_checked' onClick={() => { navigate("/dashboard/financial") }}>
                        <img src={financialIcon} />
                        <p>Financeiro</p>
                    </div>

            } */}


            {localStorage.getItem("@PERMISSION") == "5"

                ?
                <></>
                :
                window.location.pathname == "/dashboard/models"

                    ?
                    <div className='checked' onClick={() => { navigate("/dashboard/models") }}>
                        <img src={categoryCheck} />
                        <p>Categorias</p>
                    </div>

                    :
                    <div className='not_checked' onClick={() => { navigate("/dashboard/models") }}>
                        <img src={categoriesIcon} />
                        <p>Categorias</p>
                    </div>
            }

            {localStorage.getItem("@PERMISSION") == "5"

                ?
                <></>
                :
                window.location.pathname == "/dashboard/brands"

                    ?
                    <div className='checked' onClick={() => { navigate("/dashboard/brands") }}>
                        <img src={categoryCheck} />
                        <p>Marcas</p>
                    </div>

                    :
                    <div className='not_checked' onClick={() => { navigate("/dashboard/brands") }}>
                        <img src={categoriesIcon} />
                        <p>Marcas</p>
                    </div>
            }

            {localStorage.getItem("@PERMISSION") == "5" ?

                <></>
                :
                window.location.pathname == "/dashboard/services"

                    ?
                    <div className='checked' onClick={() => { navigate("/dashboard/services") }}>
                        <img src={serviceCheckIcon} />
                        <p>Produtos</p>
                    </div>

                    :
                    <div className='not_checked' onClick={() => { navigate("/dashboard/services") }}>
                        <img src={serviceIcon} />
                        <p>Produtos</p>
                    </div>
            }
            {/* 
            {localStorage.getItem("@PERMISSION") == "5"
                ?
                <></>
                :

                window.location.pathname == "/dashboard/users"

                    ?
                    <div className='checked' onClick={() => { navigate("/dashboard/users") }}>
                        <img src={acessCheckIcon} />
                        <p>Acessos</p>
                    </div>
                    :

                    <div className='not_checked' onClick={() => { navigate("/dashboard/users") }} >
                        <img src={acessIcon} />
                        <p>Acessos</p>
                    </div>
            } */}


            {localStorage.getItem("@PERMISSION") == "5"

                ?


                <div className='checked' onClick={() => { navigate("/dashboard/schedules") }}>
                    <img src={acessCheckIcon} />
                    <p>Agendamentos</p>
                </div>
                :

                <></>

            }


            <div className='not_checked' onClick={() => exit()}>
                <img className='exit_icon' src={exitIcon} />
                <></>
            </div>




        </div>

    )
}

export default Sidebar