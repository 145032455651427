import React, { useState, useEffect } from 'react'
import ThowColumLayout from '../../layouts/ThowColumLayout'
import Input from '../../components/Input'
import Button from '../../components/Button'
import landingIcon from '../../images/svg/landingIcon.svg'
import companyIcon from '../../images/png/LOGO-TKG.jpg'
import ButtonMobile from '../../components/ButtonMobile'
import api from '../../services/api'
import loginIcon from '../../images/png/fbr-bronco-accesorios-engate-reboque.jpg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Landing = () => {

    const [mail, setMail] = useState()
    const [password, setPassword] = useState()

    /* MESSAGE ERROS */

    const [mailError, setMailError] = useState()
    const [paswordError, setPasswordError] = useState()

    /* BTN STATE */

    const [btnState, setBtnState] = useState()


    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    const getLogin = async () => {


        if (password == undefined) {

            setPasswordError("senha deve conter no mínimo 8 caracteres")

        }


        const validMail = validateEmail(mail)

        if (validMail == false) {
            setMailError("email inválido")
            setBtnState(true)

        } else {
            setMailError()
            setBtnState(false)

        }


        if (String(password).length <= 7) {

            setPasswordError("senha deve conter no mínimo 8 caracteres")
            setBtnState(true)

        } else {

            setBtnState(false)
            setPasswordError()

        }




        if (mail != undefined && password != undefined && validMail != false) {

            const response = await api.post("session/storage/panel", {

                "mail": mail,
                "password": password


            })

            if (response.status == 200) {

                localStorage.setItem("@PERMISSION", response.data.user.permission)
                localStorage.setItem("@USER_NAME_2", response.data.user.name)
                localStorage.setItem("@USER_NAME", response.data.user.name)
                localStorage.setItem("@USER_MAIL", response.data.user.mail)
                localStorage.setItem("@USER_PASSWORD", password)
                localStorage.setItem("@USER_TOKEN", response.data.token)
                localStorage.setItem("@USER_ID", response.data.user.id)

                localStorage.setItem("@USER_PICTURE", response.data.user.picture != null ? response.data.user.picture : false)

                if (response.data.user.permission == "5") {
                    window.location.href = "/dashboard/schedules"
                    return false

                }

                window.location.href = "/dashboard/"
            }


            if (response.status != 200) {

                toast.error('Email ou senha inválidos!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });


            }


        }
    }

    const image = {
        width: "570px",
        height: "100vh",
    },

        containerColum2 = {
            paddingTop: "3.5rem",

        }

    useEffect(() => {

        if (mail != undefined) {

            const validMail = validateEmail(mail)

            if (validMail == false) {
                setMailError("email inválido")
                setBtnState(true)

            } else {
                setMailError()
                setBtnState(false)

            }
        }

        if (password != undefined) {

            if (String(password).length <= 7) {

                setPasswordError("senha deve conter no mínimo 8 caracteres")
                setBtnState(true)

            } else {

                setBtnState(false)
                setPasswordError()

            }

        }


    }, [mail, password])

    return (

        <ThowColumLayout
            page={"Landing"}
            colum1={
                <div>
                    <img src={loginIcon} className='image_landing' />
                </div>
            }

            colum2={

                <div>
                    <ToastContainer />

                    <div className='container_landing_image'>
                        <img src={companyIcon} />
                    </div>

                    <div className='container_inputs_landing'>
                        <p>Dados de acesso</p>

                        <Input name="E-mail" error={mailError} onChange={(e) => setMail(e.target.value)} placeholder="Insira seu e-mail" type={"text"} />
                        <Input topEye={"50px"} name="Senha" error={paswordError} onChange={(e) => setPassword(e.target.value)} placeholder="Insira sua senha" type={"password"} />
                        <ButtonMobile name="Entrar" disabled={btnState} onClick={() => getLogin()} />
                        <Button width="416px" height="48px" background="black"
                            borderRadius="60px" marginTop="3rem" name="Entrar"
                            border="none" disabled={btnState} onClick={() => getLogin()} />
                    </div>

                </div>

            }


        >

        </ThowColumLayout>

    )

}

export default Landing