import firebase from "firebase/compat/app"
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyD6J4kUdzWKbzG2Ps_Vh4jUb0bg2bkTg2o",
    authDomain: "storage-tkg.firebaseapp.com",
    projectId: "storage-tkg",
    storageBucket: "storage-tkg.appspot.com",
    messagingSenderId: "494096862421",
    appId: "1:494096862421:web:b85c2831e9cd6b4370e65c",
    measurementId: "G-50QGT9MZ8L"
};


// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);


export default app