import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppStack from './routes/AppStack';
import UserProvider from './context/user';
import FilterProvider from './context/filter';
import CategoryProvider from './context/categorys';
import ServiceProvider from './context/service'
import ModalProvider from './context/modal';
import SchedulesProvider from './context/schedules';
import VariationProvider from './context/variation';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <VariationProvider>
    <SchedulesProvider>
    <ModalProvider>
    <ServiceProvider>
    <CategoryProvider>
    <FilterProvider>
    <UserProvider>
    <AppStack />
    </UserProvider>
    </FilterProvider>
    </CategoryProvider>
    </ServiceProvider>
    </ModalProvider>
    </SchedulesProvider>
    </VariationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
