import React from 'react'
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Landing from '../pages/Landing'
import Dashboard from '../pages/Dashboard'
import Financial from '../pages/Financial'
import Categorys from '../pages/Categorys'
import Services from '../pages/Services'
import Models from '../pages/Models'
import PrivateRoute from '../pages/PrivateRoute'

const AppStack = () => {
    return(

        <BrowserRouter>
        
            <Routes>
                <Route path='/' element={<Landing/>}/>
                <Route path='/dashboard' element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
                <Route path='/dashboard/financial' element={<PrivateRoute><Financial/></PrivateRoute>}/>
                <Route path='/dashboard/brands' element={<PrivateRoute><Categorys/></PrivateRoute>}/>
                <Route path='/dashboard/services' element={<PrivateRoute><Services/></PrivateRoute>}/>
                <Route path='/dashboard/models' element={<PrivateRoute><Models/></PrivateRoute>}/>

            </Routes>

        </BrowserRouter>

    )
}

export default  AppStack